import styled from "@emotion/styled";
import { Container, Typography } from "@mui/material";

const FooterDiv = styled.div`
  bottom: 0;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  min-height: 30vh;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
`;

export default function Footer() {
  return (
    <FooterDiv>
      <Container maxWidth="xl" component="footer">
        <Typography color="textSecondary" align="center" marginTop={10}>
          {"Copyright © "}
          Alchimix Solutions
          {" " + new Date().getFullYear()}
        </Typography>
      </Container>
    </FooterDiv>
  );
}
