import styled from "@emotion/styled";
import ServiceBox from "@mix/components/ServiceBox";
import { Container, Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useInView } from "react-intersection-observer";
import { servicesData } from "src/constants";


const ServicesSection = styled.section`
  padding: 50px 0;
  text-align: center;
  @media (min-width: 600px) {
    padding: 50px 0;
  }
`;

const Services = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { ref, inView } = useInView({
    threshold: isMobile ? 0.1 : 0.5,
    triggerOnce: false,
  });

  return (
    <ServicesSection  ref={ref} style={{ opacity: inView ? 1 : 0, transition: 'opacity 1.5s ease-out' }}>
      <Container maxWidth="lg"       
      sx={{
        color: "white",
        minHeight: "70vh",
        p: 3,
      }}>
        <Typography variant="h4" sx={{          fontSize: {
            xs: "1.75rem",
            sm: "2rem",
            md: "3rem",
            lg: "3rem",
          }}}>
          We Provide A Lot of Cool Services
        </Typography>
        <Typography paragraph>
          Far far away, behind the word mountains, far from the countries
          Vokalia and Consonantia...
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {servicesData.map((service, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <ServiceBox
                icon={service.icon}
                title={service.title}
                description={service.description}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </ServicesSection>
  );
};

export default Services;
