import styled from "@emotion/styled";
import {
  Alert,
  AlertColor,
  Button,
  CircularProgress,
  Container as MuiContainer,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useState } from "react";
import emailjs from "@emailjs/browser";

const ContactSection = styled.section`
  padding: 50px 0;
  text-align: center;
`;

const TransparentContainer = styled(MuiContainer)`
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 15px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  @media (max-width: 600px) {
    padding: 10px;
  }
`;

const ContactForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormItem = styled(TextField)`
  margin: 10px 0;
  width: calc(100% - 20px);
  @media (max-width: 600px) {
    width: calc(100% - 40px);
  }

  & label.Mui-focused {
    color: white;
  }
  & .MuiInput-underline:after {
    border-bottom-color: white;
  }
  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: white;
    }
    &:hover fieldset {
      border-color: white;
    }
    &.Mui-focused fieldset {
      border-color: white;
    }
    & input,
    & textarea {
      color: white;
      background-color: transparent;
    }
  }

  & input:-webkit-autofill,
  & textarea:-webkit-autofill {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 1, 1.5) inset !important;
    box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 1) inset !important;
    -webkit-text-fill-color: white !important;
  }
`;

const SubmitButton = styled(Button)`
  margin-top: 20px;
  background-color: #8e24aa;
  color: white;
  &:hover {
    background-color: #7b1fa2;
  }
`;

const ContactUs = () => {
  const { ref, inView } = useInView({
    threshold: 0.5,
    triggerOnce: false,
  });

  const [from_name, setFromName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");

  const templateParams = {
    from_name: from_name,
    from_email: email,
    message: message,
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    setLoading(true);
    emailjs
      .send(
        String(process.env.REACT_APP_EMAILJS_SERVICE_ID),
        String(process.env.REACT_APP_EMAILJS_TEMPLATE_ID),
        templateParams,
        String(process.env.REACT_APP_EMAILJS_API_KEY)
      )
      .then(
        (response: any) => {
          setFromName("");
          setEmail("");
          setMessage("");
          setLoading(false);
          setSnackbarSeverity("success");
          setSnackbarMessage("Message sent successfully!");
          setOpenSnackbar(true);
        },
        (err) => {
          setLoading(false);
          setSnackbarSeverity("error");
          setSnackbarMessage("Failed to send message.");
          setOpenSnackbar(true);
        }
      );
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  console.log(process.env);


  return (
    <>
      <ContactSection
        ref={ref}
        style={{ opacity: inView ? 1 : 0, transition: "opacity 0.5s ease-out" }}
      >
        <MuiContainer maxWidth="md">
          <Typography variant="h4" gutterBottom sx={{ color: "white" }}>
            Contact Us
          </Typography>
          <Typography paragraph sx={{ color: "white" }}>
            Have questions? We would love to hear from you!
          </Typography>
        </MuiContainer>
        <TransparentContainer maxWidth="md">
          <ContactForm onSubmit={handleSubmit}>
            <FormItem
              label="Name"
              variant="outlined"
              name="name"
              value={from_name}
              onChange={(e) => setFromName(e.target.value)}
            />
            <FormItem
              label="Email"
              variant="outlined"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormItem
              label="Message"
              variant="outlined"
              name="message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              multiline
              rows={4}
            />
            <SubmitButton variant="contained" type="submit" disabled={loading}>
              {loading ? (
                <CircularProgress size={24} sx={{ color: "#fff" }} />
              ) : (
                "Send Message"
              )}
            </SubmitButton>
          </ContactForm>
        </TransparentContainer>
      </ContactSection>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ContactUs;
