import { ThemeOptions } from "@mui/material/styles";

const Theme: ThemeOptions = {
  palette: {
    primary: {
      light: "#3d8b6a",
      main: "#0D6E45",
      dark: "#094d30",
      contrastText: "#ffffff",
    },
    secondary: {
      light: "#c68f5b",
      main: "#b87333",
      dark: "#805023",
      contrastText: "#ffffff",
    },
    error: {
      light: "#f05357",
      main: "#ED282E",
      dark: "#a51c20",
      contrastText: "#fff",
    },
    text: {
      primary: "#646777",
      secondary: "#999",
      disabled: "rgba(0, 0, 0, 0.38)",
    },
    background: {
      default: "#000000",
    },
  },
  typography: {
    fontFamily: "Montserrat, sans-serif",
    h5: {
      fontSize: "1.5rem",
    },
    h6: {
      fontSize: "1.2rem",
    },
  },
  mixins: {
    toolbar: {
      minHeight: 60,
    },
  },
  spacing: 4,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          paddingRight: '0 !important',
          '&::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            backgroundColor: 'rgba(0,0,0,0.05)',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0,0,0,0.4)',
            minHeight: '24px',
            border: '2px solid rgba(0,0,0,0.05)',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,0.7)',
          }
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'rgba(0,0,0,0.05)',
          },
          '&::-webkit-scrollbar-thumb': {
            borderRadius: '4px',
            backgroundColor: 'rgba(0,0,0,0.4)',
            border: '2px solid rgba(0,0,0,0.05)',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: 'rgba(0,0,0,0.7)',
          }
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: "unset",
        },
      },
    },
  },
};

export default Theme;
