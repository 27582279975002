import { Box, Container, Typography } from "@mui/material";
import { keyframes } from "@mui/system";
import { useEffect, useState } from "react";

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const HomeSection = () => {
  const [showWelcome, setShowWelcome] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setShowWelcome(true);
    }, 500);

    const timer2 = setTimeout(() => {
      setShowDescription(true);
    }, 3000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
    };
  }, []);

  return (
    <Container
      maxWidth="xl"
      sx={{
        minHeight: "70vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{ color: "white", textAlign: "center", p: 2, minHeight: "5vh" }}
      >
        <Typography
          variant="h2"
          gutterBottom
          component="div"
          sx={{
            opacity: showWelcome ? 1 : 0,
            animation: showWelcome ? `${fadeIn} 1s ease-out` : "none",
            fontSize: {
              xs: "1.75rem",
              sm: "2rem",
              md: "3rem",
              lg: "3.75rem",
            },
          }}
        >
          Welcome to Alchimix Solutions
        </Typography>
      </Box>
      <Box sx={{ color: "white", textAlign: "center", p: 2, minHeight: "6vh" }}>
        <Typography
          variant="h5"
          gutterBottom
          component="div"
          sx={{
            opacity: showDescription ? 1 : 0,
            animation: showDescription ? `${fadeIn} 1s ease-out` : "none",
            fontSize: {
              xs: "1rem", 
              sm: "0.75rem", 
              md: "1rem", 
              lg: "1.5rem",
            },
          }}
        >
          Where Technology Meets the Alchemy of Innovation
        </Typography>
      </Box>
    </Container>
  );
};

export default HomeSection;
