import {
  faCode,
  faComments,
  faCompassDrafting,
  faMobileAlt,
  faServer,
  faShieldHalved
} from "@fortawesome/free-solid-svg-icons";

export const servicesData = [
  {
    icon: faCode,
    title: "Development",
    description: "Our development team delivers custom solutions ranging from websites to complex applications, ensuring quality and efficiency with cutting-edge technologies.",
  },
  {
    icon: faCompassDrafting,
    title: "UX/UI",
    description: "We create memorable user experiences with intuitive and attractive interfaces, focusing on usability to engage and retain users.",
  },
  {
    icon: faComments,
    title: "Consultancy",
    description: "Our specialized consultants help your business navigate the complexities of the digital world, offering tailored strategies to boost growth and innovation.",
  },
  {
    icon: faServer,
    title: "DevOps",
    description: "We integrate development and operations to streamline deliveries, improve efficiency, and enhance software quality with advanced DevOps practices.",
  },
  {
    icon: faShieldHalved,
    title: "Security",
    description: "We prioritize the security of your systems with vulnerability assessment services, implementation of best security practices, and continuous monitoring.",
  },
  {
    icon: faMobileAlt,
    title: "Mobile Development",
    description: "We develop innovative and responsive mobile apps for iOS and Android, focused on providing the best user experience and meeting business needs.",
  },
];
