/** @jsxImportSource @emotion/react */
import React from 'react';
import styled from '@emotion/styled';
import { SectionProps } from '@mix/types/components';

const BackgroundImage = styled.div<{ src: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: auto;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.5;
  z-index: -1;
`;

const VideoBackground = styled.video`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: -1;  
`;

const StyledSection = styled.div<SectionProps>`
  position: relative;
  width: 100vw;
  height: ${(props) => props.height || '100vh'};
  background-color: ${(props) => props.backgroundColor || 'transparent'};
  box-shadow: ${(props) => props.boxShadow || 'none'};
  margin: 0;
  padding: 0;
  overflow: hidden;

  @media (max-width: 768px) {
    height: auto;
    background-size: contain;
  }
`;

const Section: React.FC<SectionProps> = ({ children, backgroundVideo, backgroundImage, ...props }) => {
  return (
    <StyledSection {...props}>
      {backgroundImage && <BackgroundImage src={backgroundImage} />}
      {backgroundVideo && (
        <VideoBackground autoPlay loop muted>
          <source src={backgroundVideo} type="video/mp4" />
          Your browser does not support videos.
        </VideoBackground>
      )}
      <div style={{ position: 'relative', zIndex: 1 }}>
        {children}
      </div>
    </StyledSection>
  );
};

export default Section;
