import Footer from "@mix/components/Footer";
import Header from "@mix/components/Header";
import Section from "@mix/components/Section";
import ContactUs from "@mix/containers/Contact";
import HomeSection from "@mix/containers/Home";
import AboutSection from "@mix/containers/About";
import Services from "@mix/containers/Services";
import { Container } from "@mui/material";

export default function Home() {
  return (
    <>
      <Section
        height="800px"
        backgroundVideo="videos/background.mp4"
        boxShadow="inset 0 -120px 90px -10px black"
      >
        <Header />
        <HomeSection />
      </Section>
      <Section height="120vh" backgroundImage="images/purplebackground.png">
        <Container maxWidth="xl" id="about">
          <AboutSection />
        </Container>
      </Section>
      <Section backgroundColor="black" height="auto">
        <Container maxWidth="xl" id="services">
          <Services />
        </Container>
      </Section>
      <Section
        backgroundVideo="videos/background.mp4"
        height="auto"
        boxShadow="inset 0px 120px 90px -10px black"
      >
        <Container maxWidth="xl" id="contact">
          <ContactUs />
        </Container>
        <Footer />
      </Section>
    </>
  );
}
