import Theme from "@mix/config/theme";
import { CssBaseline } from "@mui/material";
import { ptBR } from "@mui/material/locale";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Router from "./router";

export default function App() {
  const themeConfig = createTheme(Theme, ptBR);

  return (
    <ThemeProvider theme={themeConfig}>
      <CssBaseline />
      <Router />
    </ThemeProvider>
  );
}
