import styled from "@emotion/styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ServiceBoxProps } from "@mix/types/components";
import { Paper, Typography } from "@mui/material";
import { keyframes } from "@emotion/react";

// Define a animação utilizando keyframes
const breatheAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const ServiceItem = styled(Paper)`
  padding: 20px;
  margin: 10px;
  text-align: center;
  box-shadow: none;
  min-height: 270px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);

    // Ativa a animação do ícone apenas quando ServiceItem está em hover
    & > div > svg {
      animation: ${breatheAnimation} 2s ease-in-out infinite;
    }
  }
`;

const IconWrapper = styled.div`
  margin-bottom: 20px;
  & > svg {
    font-size: 3rem;
    color: #9424cb;
  }
`;

const ServiceBox: React.FC<ServiceBoxProps> = ({
    icon,
    title,
    description,
  }) => {
    return (
      <ServiceItem>
        <IconWrapper>
          <FontAwesomeIcon icon={icon} />
        </IconWrapper>
        <Typography variant="h6">{title}</Typography>
        <Typography>{description}</Typography>
      </ServiceItem>
    );
  };

export default ServiceBox;
