import { Box, Container, Typography } from "@mui/material";
import { useInView } from "react-intersection-observer";

const AboutSection = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.5,
  });

  const offsetX = inView ? 0 : 50;
  const opacity = inView ? 1 : 0;

  return (
    <Container
      maxWidth="lg"
      sx={{
        minHeight: "70vh",
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        alignItems: "center",
        justifyContent: "center",
        p: 3,
      }}
    >
      <Box
        ref={ref}
        sx={{
          width: { xs: "100%", md: "50%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          transition: "transform 1s ease-out, opacity 1s ease-out",
          transform: `translateX(${-offsetX}%)`,
          opacity,
        }}
      >
        <img
          src="images/fingerprint.svg"
          alt="Alchimix Logo"
          style={{ width: "70%", maxWidth: "550px", height: "auto" }}
        />
      </Box>
      <Box
        sx={{
          width: { xs: "100%", md: "50%" },
          color: "white",
          textAlign: "center",
          p: { xs: 2, md: 4 },
          transition: "transform 1s ease-out, opacity 1s ease-out",
          transform: `translateX(${offsetX}%)`,
          opacity,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "left",
        }}
      >
        <Typography variant="h2" gutterBottom>
          Your Business, Your Way
        </Typography>
        <Typography paragraph>
        At Alchimix Solutions, we recognize that success isn't one-size-fits-all. We're committed to crafting customized solutions tailored to your business's unique needs and challenges. Our team collaborates closely with you to transform your vision into reality, ensuring our approach perfectly reflects your business's identity and goals. Choose Alchimix Solutions—where your business is our blueprint for success.
        </Typography>
      </Box>
    </Container>
  );
};

export default AboutSection;
