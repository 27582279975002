import styled from "@emotion/styled";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Button,
  Container,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";

const StyledToolbar = styled(Toolbar)`
  height: 150px;
  margin-left: 9%;
  margin-right: 9%;
`;

const ColoredButton = styled(Button)`
  color: white !important;
  position: relative;
  padding: 1em 1.5em;
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
  margin: 1em 0.8em;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 2px;
    width: 0;
    left: 0;
    bottom: 0;
    background-color: white;
    transition: width 0.3s ease-in-out;
  }

  &:hover::after {
    width: 100%;
  }
`;

const LogoPosition = styled(Typography)`
  flex-grow: 1;
  margin-top: 20px;
`;

const Header = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNavigation = (href: string) => {
    setMobileOpen(false);
    if (href.startsWith("#")) {
      const id = href.replace("#", "");
      const section = document.getElementById(id);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    }
  };

  const drawerItems = [
    { text: "Home", href: "/" },
    { text: "About Us", href: "#about" },
    { text: "Services", href: "#services" },
    { text: "Contact Us", href: "#contact" },
  ];

  const drawer = (
    <List>
      {drawerItems.map((item) => (
        <ListItemButton
          key={item.text}
          onClick={() => handleNavigation(item.href)}
        >
          <ListItemText primary={item.text} />
        </ListItemButton>
      ))}
    </List>
  );

  return (
    <Container maxWidth="xl">
      <AppBar
        sx={{
          backgroundColor: "transparent",
          position: "unset",
          boxShadow: "unset",
          padding: "0px !important",
          overflow: "visible !important",
        }}
      >
        <StyledToolbar
          sx={{
            backgroundColor: "transparent",
          }}
        >
          <LogoPosition>
            <Link to="/">
              <img
                src="images/alchimix-logo.svg"
                alt="Alchimix Logo"
                style={{ width: "200px", height: "auto" }}
              ></img>
            </Link>
          </LogoPosition>
          <Toolbar>
            {isMobile ? (
              <>
              <Container>
              <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={handleDrawerToggle}
                    sx={{
                      display: 'contents !important',
                      alignItems: 'center !important',
                      justifyContent: 'center !important',
                    }}
                  >
                    <FontAwesomeIcon icon={faBars} style={{ margin: 'auto' }}/>
                  </IconButton>
                <Drawer
                  anchor="top"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  sx={{
                    "& .MuiDrawer-paper": {
                      width: "100%",
                      maxHeight: "100vh",
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                >
                  {drawer}
                </Drawer>
              </Container>

              </>
            ) : (
              <div>
                {drawerItems.map((item) => (
                  <ColoredButton
                    color="inherit"
                    onClick={() => handleNavigation(item.href)}
                    key={item.text}
                  >
                    {item.text}
                  </ColoredButton>
                ))}
              </div>
            )}
          </Toolbar>
        </StyledToolbar>
      </AppBar>
    </Container>
  );
};

export default Header;
